import styled from "@emotion/styled"
import Img from "gatsby-image"
import { Colors, mediaMaxWidth } from "../../style"
import { images } from '../common/images'

const Footer = () => {
  return (
    <Background>
      <Container>
        <Img fixed={[
          images().headerLogoPC.childImageSharp.fixed,
          {
            ...images().headerLogoSP.childImageSharp.fixed,
            media: `(max-width: ${mediaMaxWidth})`,
          }
        ]} />
      </Container>
      <SecurityLink href="/security">セキュリティポリシー</SecurityLink>
    </Background>
  )
}

const Background = styled.footer`
  height: 90px;
  background-color: ${Colors.White};
  padding: 0 60px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mediaMaxWidth}) {
    margin-top: 0;
    padding: 0 16px;
    width: 100%;
  }
`

const Container = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SecurityLink = styled.a`
  font-size: 14px;
  text-wrap: nowrap;
  color: link;
`

export default Footer